<template>
  <section>
    <fetch-loading v-if="pending" />
    <fetch-error v-else-if="error" :error="error" />
    <div v-else class="bg-gray-100">
      <!--- Banner and Search section --->
      <section class="bg-int-off-white relative flex flex-col banner">
        <div class="w-full max-w-screen-2xl mx-auto bg-white shadow">
          <div class="relative flex flex-col">
            <div
              class="aspect-w-16 aspect-h-7 sm:aspect-h-6 md:aspect-h-4"
              :class="`md:border-b-5 border-int-${banner.subtitleColour}-02`"
            >
              <ImageSrcset
                v-if="banner && banner.heroImage && banner.heroImage.src"
                :source="banner.heroImage.src"
                class="w-full object-cover object-right lg:object-center"
                :alt="banner.heroImage.alt"
              />
            </div>
            <div class="z-10 flex flex-col justify-center md:absolute inset-0">
              <div
                class="bg-int-grey-01 md:bg-int-grey-01/80 text-white md:w-2/3 xl:w-1/2 p-6 md:p-8 md:pl-24 xl:pl-48 space-y-4"
              >
                <h1
                  v-if="banner"
                  class="leading-none font-medium text-3xl lg:text-4xl xl:text-5xl"
                >
                  <span v-html="banner.title"></span>
                </h1>
                <div
                  class="text-base font-medium md:text-lg xl:text-xl"
                  v-html="banner.subtitle"
                ></div>
              </div>
            </div>
          </div>
          <!-- Apply feature flag to hide international search tabs in Prod temporarily and will remove it once the FE page complete -->
          <div
            v-if="!hideIntSearch"
            class="px-4 pt-6 pb-10 md:px-14 md:py-8 md:my-16 md:mx-32 md:border md:border-int-grey-03 md:shadow-sm"
          >
            <Tabs>
              <Tab title="Find a course" header="Course Search">
                <int-search-bar
                  id="searchbar-course"
                  v-model:search-param="courseKeyword"
                  placeholder-text="Keywords - Eg. business"
                  :results-api="coursesSummary"
                  :show-button="true"
                  @search="doCourseSearch"
                >
                  <filter-dropdown
                    v-if="locations"
                    id="filterByLocation"
                    default-option="Any location"
                    :filterable="true"
                    :options="locations"
                    :selected="courseCampus"
                    @update:selected="updateCourseFilter('campus', $event)"
                  />
                </int-search-bar>
              </Tab>
              <Tab title="Find an agent" header="Agent Search">
                <!-- international agent search -->
                <int-search-bar
                  id="searchbar-agent"
                  v-model:search-param="agentKeyword"
                  placeholder-text="Search by agency name"
                  :show-button="true"
                  @search="doAgentSearch"
                >
                  <filter-dropdown
                    id="filterByCountry"
                    v-model:selected="selectedCountry"
                    default-option="Any country"
                    :filterable="true"
                    :options="countries"
                    @update:selected="fetchCities"
                  />
                  <filter-dropdown
                    id="filterByCity"
                    v-model:selected="selectedCity"
                    default-option="Any city"
                    :disabled="!selectedCountry"
                    :filterable="true"
                    :options="cities"
                  />
                </int-search-bar>
              </Tab>
            </Tabs>
          </div>
          <div v-else>
            <div
              class="container flex justify-center flex-col gap-y-3 p-6 md:flex-row md:py-14 md:max-w-5xl md:gap-x-10"
            >
              <tafe-button
                :href="INTERNATIONAL_COURSE_SEARCH_URL"
                :icon="{ name: 'IconChevronRight', side: 'right' }"
                >Find a course</tafe-button
              >
              <tafe-button
                :href="INTERNATIONAL_AGENT_SEARCH_URL"
                :icon="{ name: 'IconChevronRight', side: 'right' }"
                >Find an agent</tafe-button
              >
            </div>
          </div>
        </div>
      </section>
      <!--- Interest section --->
      <section class="bg-int-off-white py-16 mb:py-12 xl:py-16">
        <div class="container flex justify-center flex-col">
          <h2
            class="self-center mb-6 text-int-grey-01 md:mb-10 font-bold text-3.5xl"
          >
            I’m interested in
          </h2>
          <tafe-tiles :tiles="interestInTiles" :columns="4" />
        </div>
      </section>
      <!--- Course Area section --->
      <section
        v-if="popularCourseAreaList && popularCourseAreaList.length"
        class="bg-white py-16 mb:py-12 xl:py-16"
      >
        <div class="container flex justify-center flex-col">
          <h2
            class="self-center mb-6 text-int-grey-01 md:mb-12 font-bold text-3.5xl"
            :aria-label="popularCourseAreas?.title"
            tabindex="0"
          >
            {{ popularCourseAreas?.title }}
          </h2>
          <ul class="grid grid-cols-2 lg:grid-cols-4 grid-spacing">
            <li
              v-for="(courseArea, i) in popularCourseAreaList"
              :key="i"
              class="text-center"
            >
              <NuxtLink
                :to="courseArea.url"
                class="group flex flex-col text-int-grey-01 align-center no-underline hover:text-brand-blue-500 hover:underline"
              >
                <Icon
                  v-if="courseArea.icon"
                  :name="courseArea.icon"
                  class="text-2xl md:text-3xl mx-auto text-int-grey-01"
                />
                <span class="text-base md:text-lg">{{
                  courseArea.title
                }}</span></NuxtLink
              >
            </li>
          </ul>
          <div class="mt-8 md:mt-12 text-center">
            <tafe-button
              component="nuxt-link"
              to="/international/course-areas"
              class="border text-white border-brand-blue-500 bg-brand-blue-500"
              :icon="{ name: 'IconChevronRight', side: 'right' }"
            >
              View all course areas
            </tafe-button>
          </div>
        </div>
      </section>
      <!--- Course card section --->
      <section
        v-if="courseCardList && courseCardList.length"
        class="bg-int-off-white py-16 mb:py-12 xl:py-16"
      >
        <div class="container flex justify-center flex-col">
          <h2
            class="self-center mb-6 text-int-grey-01 md:mb-12 font-bold text-3.5xl"
          >
            {{ courseCards?.title }}
          </h2>
          <content-cards
            :items="courseCardList"
            class="grid grid-cols-1 md:grid-cols-3 grid-spacing"
          />
          <div class="mt-8 md:mt-12 text-center">
            <tafe-button
              v-if="!hideIntSearch"
              component="nuxt-link"
              to="/international/course-search"
              class="border text-white border-brand-blue-500 bg-brand-blue-500"
              :icon="{ name: 'IconChevronRight', side: 'right' }"
            >
              View all courses
            </tafe-button>
            <tafe-button
              v-else
              :href="INTERNATIONAL_COURSE_SEARCH_URL"
              class="border text-white border-brand-blue-500 bg-brand-blue-500"
              :icon="{ name: 'IconChevronRight', side: 'right' }"
              >View all courses</tafe-button
            >
          </div>
        </div>
      </section>
      <!--- Promotions2 section --->
      <section v-if="promotions2" class="bg-white py-16 mb:py-12 xl:py-16">
        <div class="container flex justify-center flex-col">
          <h2
            class="self-center mb-6 text-int-grey-01 md:mb-12 font-bold text-3.5xl"
          >
            {{ promotions2.promotionsTitle }}
          </h2>
          <div class="grid grid-cols-1 md:grid-cols-3 grid-spacing">
            <promotion-card
              v-for="(promo, i) in promotions2.promos"
              :key="i"
              :promo="promo"
              :index="i"
              class="flex-1"
            ></promotion-card>
          </div>
        </div>
      </section>
      <!--- Video section --->
      <section v-if="video" class="bg-int-off-white py-16 mb:py-12 xl:py-16">
        <div class="container flex justify-center flex-col">
          <h2
            class="self-center mb-6 text-center text-int-grey-01 md:mb-12 font-bold text-3.5xl"
          >
            {{ video.title }}
          </h2>
          <div
            class="flex justify-center flex-col self-center w-full md:max-w-5xl"
          >
            <VideoSection :src="video.url" />
          </div>
        </div>
      </section>
      <!--- Promotions3 section --->
      <section v-if="promotions3" class="bg-white py-16 mb:py-12 xl:py-16">
        <div class="container flex justify-center flex-col">
          <h2
            class="self-center mb-6 text-int-grey-01 md:mb-12 font-bold text-3.5xl"
          >
            {{ promotions3.promotionsTitle }}
          </h2>
          <div class="grid grid-cols-1 md:grid-cols-3 grid-spacing">
            <promotion-card
              v-for="(promo, i) in promotions3.promos"
              :key="i"
              :promo="promo"
              :index="i"
              class="flex-1"
            ></promotion-card>
          </div>
        </div>
      </section>
      <!--- Testimonials section --->
      <section
        v-if="testimonialList && testimonialList.length"
        class="bg-int-off-white py-16 mb:py-12 xl:py-16"
      >
        <div class="flex justify-center text-center flex-col">
          <h2
            class="self-center mb-6 text-int-grey-01 md:mb-12 font-bold text-3.5xl"
          >
            {{ testimonials?.title }}
          </h2>
          <testimonial-cards
            v-if="testimonialList && testimonialList.length"
            :testimonials="testimonialList"
            class="container content-row grid grid-cols-1 grid-spacing md:grid-cols-3"
          />
        </div>
      </section>
      <!--- ESOS Framework section --->
      <section v-if="headline" class="bg-white py-16 mb:py-12 xl:py-16">
        <div class="container flex justify-center text-center flex-col">
          <h2
            class="self-center mb-8 text-int-grey-01 md:mb-10 font-bold text-3.5xl"
            :aria-label="headline.title"
            tabindex="0"
          >
            {{ headline.title }}
          </h2>
          <div
            class="flex justify-center text-center items-center flex-col text-base"
            v-html="$md.render(headline.description)"
          ></div>
        </div>
      </section>
      <!--- Help and Contact section --->
      <section v-if="cta" class="bg-int-off-white py-16 mb:py-12 xl:py-16">
        <div class="container flex justify-center text-center flex-col">
          <h2
            class="self-center mb-8 text-int-grey-01 md:mb-10 font-bold text-3.5xl"
            :aria-label="cta.title"
            tabindex="0"
          >
            {{ cta.title }}
          </h2>
          <div
            class="flex justify-center self-center w-full text-base mb-8 text-int-grey-01"
            v-html="$md.render(cta.description)"
          ></div>
          <tafe-button :href="cta.url" class="self-center">
            {{ cta.ctaLabel }}
          </tafe-button>
        </div>
      </section>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useIntHomepageStore } from '~~/stores/intHomepage'
import { useIntCourseSearch } from '~/composables/international/useIntCourseSearch'
import { useFeatureFlagStore } from '~/stores/featureFlag'
import useAgentSearch from '~/composables/international/useAgentSearch'
import { isEmpty } from 'lodash-es'
import { useIntSubjectAreaLocationStore } from '~/stores/intSubjectAreaLocation'

// Liferay URL
const INTERNATIONAL_COURSE_SEARCH_URL =
  'https://www.tafensw.edu.au/international/course-search-results?k=&s=0&c=0&q=0'
const INTERNATIONAL_AGENT_SEARCH_URL =
  'https://www.tafensw.edu.au/international/agents?search=true&agency=&country=0&city=0'

const nuxtApp = useNuxtApp()
const intHomepageStore = useIntHomepageStore()
const intSubjectAreaLocationStore = useIntSubjectAreaLocationStore()

const {
  courseCampus,
  coursesSummary,
  updateCourseFilter,
  updateCourseSummaryQuery
} = await nuxtApp.runWithContext(() => useIntCourseSearch())

const { fetchCountriesCities } = useAgentSearch()

const { error, status, data } = await useAsyncData(async () => {
  const featureFlagStore = useFeatureFlagStore()
  const featureFlags = await nuxtApp.runWithContext(() =>
    featureFlagStore.fetch()
  )

  const { countries, cities } = !featureFlags.hideInternationalHomepageSearch
    ? await fetchCountriesCities('')
    : {}

  await nuxtApp.runWithContext(() =>
    Promise.all([
      intHomepageStore.fetch(),
      intSubjectAreaLocationStore.fetchSubjectAreasLocations()
    ])
  )

  return {
    featureFlags,
    cities,
    countries,
    locations: intSubjectAreaLocationStore.locations
  }
})

const featureFlags = computed(() => data.value?.featureFlags)
const pending = computed(() => status.value === 'pending')
const courseKeyword = ref('')
const courseTimeout = ref<NodeJS.Timeout | null>(null)

const agentKeyword = ref('')
const selectedCountry = ref<string | undefined>(undefined)
const selectedCity = ref<string | undefined>(undefined)
const countries = ref<string[]>(data.value?.countries || [])
const cities = ref<string[]>(data.value?.cities || [])
const locations = ref<string[]>(data.value?.locations || [])

watch(courseKeyword, (value: string) => {
  if (courseTimeout.value) {
    clearTimeout(courseTimeout.value)
  }

  if (value?.length >= 3) {
    courseTimeout.value = setTimeout(() => {
      updateCourseSummaryQuery(value)
    }, 500)
  } else if (coursesSummary.value?.length) {
    updateCourseSummaryQuery('')
  }
})

const interestInTiles = [
  {
    title: 'Certificates\n and diplomas',
    icon: 'IconShortCourses',
    color: 'bg-int-green-03',
    cta: 'View all courses',
    href: 'https://www.tafensw.edu.au/international/study/courses/certificate-advanced-diploma'
  },
  {
    title: 'Degree\n courses',
    icon: 'IconDegreeCourses',
    color: 'bg-int-orange-03',
    cta: 'View all courses',
    href: 'https://www.tafensw.edu.au/international/study/courses/degrees'
  },
  {
    title: 'University\n pathways',
    icon: 'IconOnlineCourses',
    color: 'bg-int-yellow-03',
    cta: 'View all courses',
    href: 'https://www.tafensw.edu.au/international/study/courses/pathways'
  },
  {
    title: 'English\n courses',
    icon: 'IconFindingCourses',
    color: 'bg-int-fuchsia-03',
    cta: 'View all courses',
    href: 'https://www.tafensw.edu.au/international/study/courses/english'
  }
]

// Computed
// TO DO: update backup default hero image for international home banner
const banner = computed(
  () =>
    intHomepageStore.banner ?? {
      title: '',
      subtitle: '',
      subtitleColour: 'apricot',
      heroImage: {
        src: '/images/banner-images/banner-default',
        alt: 'A woman making a dress.'
      }
    }
)

const socialPreviewImageUrl = computed(() => {
  return `${banner.value.heroImage.src}-xl.jpg`
})

const popularCourseAreaList = computed(() => {
  return intHomepageStore.popularCourseAreaList
})

const popularCourseAreas = computed(() => {
  return intHomepageStore.popularCourseAreas
})

const headline = computed(() => {
  return intHomepageStore.headline
})

const cta = computed(() => {
  return intHomepageStore.cta
})

const video = computed(() => {
  return intHomepageStore.video
})

const courseCards = computed(() => {
  return intHomepageStore.courseCards
})

const courseCardList = computed(() => {
  return intHomepageStore.courseCardList
})

const promotions2 = computed(() => {
  return intHomepageStore.promotions2
})

const promotions3 = computed(() => {
  return intHomepageStore.promotions3
})

const testimonials = computed(() => {
  return intHomepageStore.testimonials
})

const testimonialList = computed(() => {
  return intHomepageStore.testimonialList
})

const hideIntSearch = computed(() => {
  return featureFlags.value?.hideInternationalHomepageSearch
})

// Methods
function doCourseSearch(keyword: string) {
  const router = useRouter()
  router.push({
    name: 'international-search',
    query: {
      keyword,
      ...(courseCampus.value &&
        courseCampus.value !== 'Any location' && {
          location: courseCampus.value
        })
    }
  })
}

function doAgentSearch(keyword: string) {
  const router = useRouter()
  router.push({
    name: 'international-agent-search',
    query: { keyword, country: selectedCountry.value, city: selectedCity.value }
  })
}

async function fetchCities(country: string) {
  // clear currently selected city
  selectedCity.value = undefined

  // fetch either all cities or cities for a specific country
  const result = !isEmpty(country)
    ? await fetchCountriesCities(country)
    : await fetchCountriesCities('')

  cities.value = result.cities
}

//TO DO: update head meta content for international home page
nuxtApp.runWithContext(() => {
  useHead({
    title: 'International | TAFE International - TAFE NSW.',
    meta: [
      {
        property: 'og:image',
        content: socialPreviewImageUrl.value
      },
      {
        property: 'og:image:secure_url',
        content: socialPreviewImageUrl.value
      },
      { property: 'og:image:width', content: '1263' },
      { property: 'twitter:card', content: 'summary_large_image' }
    ]
  })
})
</script>
<style scoped lang="postcss">
:deep(.tabs-wrapper) {
  .tabs-header {
    ul {
      @apply border-b-0 pl-0;
    }

    li.selected {
      @apply border-b-transparent bg-brand-blue-500 text-white border-brand-blue-500;
    }

    li {
      @apply border border-brand-grey-200 text-brand-blue-500 bg-white;
    }
  }
  .tabs-content {
    @apply p-0;
  }
}

:deep(#searchbar-course) {
  @appy: pl-4;
}

:deep(#searchbar-course + label > .tf-icon) {
  @apply hidden;
}

:deep(#searchbar-agent + label > .tf-icon) {
  @apply hidden;
}
</style>
